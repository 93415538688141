
import React, { Suspense } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import MenuBar from '../MenuBar/MenuBar'
import { Spin } from 'antd'
import './Page.css'

interface Page1Props {
  jwtToken: string;
}
interface Page1State {
  restrictionList: Array<string>
  store: RestrictionStore
}
// The key would be the channel the components are listening on and the array would be
// the record to restrict by
interface RestrictionStore {
  [key: string]: Array<string>
}
const ResponsiveGridLayout = WidthProvider(Responsive);

  export default class Page1 extends React.Component<Page1Props, Page1State> {
      constructor(props: Page1Props) {
      super(props)
      this.state = {
        restrictionList: [],
        store: {},
      }
      this.updateRestrictionList = this.updateRestrictionList.bind(this)
      this.updateStore = this.updateStore.bind(this)
    }
    componentDidMount() {
      this.setState({
        restrictionList: new URLSearchParams(window.location.search)
          .toString()
          .split('&'),
      })
    }
    updateRestrictionList(queryParams: string): string {
      this.setState({
        restrictionList: new URLSearchParams(queryParams).toString().split('&'),
      })
      return queryParams
    }
    updateStore(key: string, record: Array<string>) {
      let myStore = this.state.store || { [key]: record }
      myStore[key] = record
      this.setState({ store: myStore })
    }
    render() {
      return (
        <div>
          <MenuBar />
          <div className='grid-container'>
            <ul className='grid-list'>
              <Suspense fallback={<Spin size="default"/>}>
              <li>
                <DynamicGrid route={'/animal_plots'}
                             token={this.props.jwtToken}
                             columns={4}
                             rowHeight={1000}
                             componentList={['metadata', 'plot:plotly:scatter', 'plot:plotly:scatter', 'plot:plotly:scatter']}
                             routeList={['/subject_meta', '/last20_stats_a', '/last20_stats_b', '/stimulus_stats']}/>
              </li>
              </Suspense>
            </ul>
          </div>
        </div>
      )
    }
  }
const DynamicGrid = React.lazy(() => import('../DynamicGrid'))
