
export const MenuBarData = [
    {
        title: 'Rig',
        path: '/rig',
        cName: 'nav-text'
    },
    {
        title: 'Animal',
        path: '/animal',
        cName: 'nav-text'
    },
    {
        title: 'AnimalReport',
        path: '/subject_report',
        cName: 'nav-text'
    },
]