
import React, { Suspense } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import MenuBar from '../MenuBar/MenuBar'
import { Spin } from 'antd'
import './Page.css'

interface Page1Props {
  jwtToken: string;
}
interface Page1State {
  restrictionList: Array<string>
  store: RestrictionStore
}
// The key would be the channel the components are listening on and the array would be
// the record to restrict by
interface RestrictionStore {
  [key: string]: Array<string>
}
const ResponsiveGridLayout = WidthProvider(Responsive);

  export default class Page1 extends React.Component<Page1Props, Page1State> {
      constructor(props: Page1Props) {
      super(props)
      this.state = {
        restrictionList: [],
        store: {},
      }
      this.updateRestrictionList = this.updateRestrictionList.bind(this)
      this.updateStore = this.updateStore.bind(this)
    }
    componentDidMount() {
      this.setState({
        restrictionList: new URLSearchParams(window.location.search)
          .toString()
          .split('&'),
      })
    }
    updateRestrictionList(queryParams: string): string {
      this.setState({
        restrictionList: new URLSearchParams(queryParams).toString().split('&'),
      })
      return queryParams
    }
    updateStore(key: string, record: Array<string>) {
      let myStore = this.state.store || { [key]: record }
      myStore[key] = record
      this.setState({ store: myStore })
    }
    render() {
      return (
        <div>
          <MenuBar />
          <div className='grid-container'>
            <ul className='grid-list'>
              <li>
                <Suspense fallback={<Spin size="default"/>}>
                <ResponsiveGridLayout className="mygrid" rowHeight={700}
                  measureBeforeMount={true}
                  breakpoints={{lg: 1200, sm: 768}}
                  cols={{lg: 1, sm: 1}}
                  useCSSTransforms={true}>
                  <div key='Animals status' data-grid={{x: 0, y: 0, w: 1, h: 1, static: true}}>
                  <TableView token={this.props.jwtToken} route='/query_animal' tableName='Animals status'  updateRestrictionList={this.updateRestrictionList}/>
                  </div>
                </ResponsiveGridLayout>
                </Suspense>
              </li>
            </ul>
          </div>
        </div>
      )
    }
  }
const TableView = React.lazy(() => import('../Table/TableView'))
