
import React from 'react';
import './App.css';
import Login from './Components/Login/Login';
import {Route, BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';
import Footer from './Components/Footer/Footer'
import Header from './Components/Header/Header'
import NotFound from './Components/Errors/NotFound'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'

import Rig from './Components/Pages/Rig' 
import Animal from './Components/Pages/Animal' 
import AnimalReport from './Components/Pages/AnimalReport' 

window.onbeforeunload = () => '';

interface DJGUIAppProps {
}

interface DJGUIAppState {
  jwtToken: string; // Storage object for JWT token obtain after logging in successfully
  hostname: string; // Name of the database that the user is connected to
}

/**
 * React top level component for the GUI
 */
export default class App extends React.Component<DJGUIAppProps, DJGUIAppState> {
  constructor(props: DJGUIAppProps) {
    super(props);
    this.state = {
      jwtToken: '',
      hostname: ''
    };

    this.setJWTTokenAndHostName = this.setJWTTokenAndHostName.bind(this);
  }

  /**
   * Setter function for jwt token and host name
   * @param jwt JWT token obtain after logging sucessfully from the backend
   * @param hostname Hostname of the database that is being connected to
   */
  setJWTTokenAndHostName(jwt: string, hostname: string) {
    this.setState({jwtToken: jwt, hostname: hostname});
  }

  render() {
    return (
      <div>
        <Header text='Powered by datajoint' imageRoute={require('./logo.svg')['default']}/>
        <Router>
          <div className='content'>
            <Switch>
              <Route exact path='/'>{this.state.jwtToken !== '' ? <Redirect to='/rig'/> : <Redirect to='/login'/>}</Route>
              <Route path='/login'>{this.state.jwtToken !== '' ? <Redirect to='/rig'/> : <Login setJWTTokenAndHostName={this.setJWTTokenAndHostName} imageRoute={require("./logo.svg")["default"]}></Login>}</Route>
              <Route path='/rig*'>{this.state.jwtToken !== '' ? <Rig jwtToken={this.state.jwtToken}></Rig> : <Redirect to='/login'/>}</Route>
              <Route path='/animal*'>{this.state.jwtToken !== '' ? <Animal jwtToken={this.state.jwtToken}></Animal> : <Redirect to='/login'/>}</Route>
              <Route path='/subject_report*'>{this.state.jwtToken !== '' ? <AnimalReport jwtToken={this.state.jwtToken}></AnimalReport> : <Redirect to='/login'/>}</Route>
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
        <Footer/>
      </div>
    );
  }
}
